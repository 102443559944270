.njc-body .nc-modal-full-page.njc-template-editor {
  align-items: center;
}

/* New UI Template Editor Styling*/
.njc-template-editor {
  overflow: visible !important;

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .builder-brand-wrap , .builder-brand-wrap-with-whitelabel {
    display: none;
  }
  .builder-navbar {
    height: 40px;
    width: calc(~"100% -"  241px;);
    background-color: var(--template-main-100);
    box-shadow: none;
    border-bottom: 1px solid var(--template-border);
    cursor: default;

    .document-title-text-wrap {
      max-width: calc(~"100% -" 200px);
    }
    .document-title-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      padding-left: 16px;
      color: var(--template-main-0);
      font-style: normal;
      .njc-font-1;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 4px;;
        cursor: pointer;
      }
      .njc-temp-template-name-edit {
        display: none;
      }
      &:hover {
        span {
          width: auto;
          max-width:calc(~"100% -" 88px);
        }
        .njc-temp-template-name-edit {
          display: flex;
        }
      }
      &:before {
        content: "Template: ";
        font-size: 12px;
        color: var(--template-main-60);
      }

      &.document-title-text-notes:before {
        content: "Account/Report/Template: ";
      }

      // Styling Name Edit
      .property-label {
        display: none;
      }
      .setting-properties-wrap {
        background-color: transparent;
        width:calc(~"100% -" 60px);
        .settings-panel-property-content {
          padding: 0;
          margin: 0;
          .w-input {
            .njc-temp-input-form;
            margin-left: 4px;
          }
        }
      }

      .njc-temp-template-name-edit {
        color: var(--template-main-50);
        width: 16px;
        height: 16px;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .select-account-data-field {
      padding: 0px;
      border-left: 1px solid #10151D;
      border-right: 1px solid #10151D;
      flex: 0 1 40% !important;
      max-width: 400px;

      .select2-selection--single {
        min-height: 39px !important;
        height: 39px;
        border: 0px solid #fff;
        background-color: transparent;


        .select2-selection__rendered {
          color: var(--template-main-30);
          font-size: 12px;
          line-height: 34px !important;
        }
      }

      .select2-selection {
        background-image: url("/images/njc/njc-account-grayscale-50.svg") , url("/images/njc/njc-icon-dropdown-medium.svg");
        padding-left: 40px;
        background-position: 14px 9px , calc(~"100% -" 20px);
        background-repeat: no-repeat;
      }
      .select2-container--open {
        .select2-selection {
          background-color: var(--template-hover);
        }
      }
    }

    .template-nav-button-wrap {
      flex-basis: auto !important;
      height: 40px;
      align-items: center;

      .builder-nav-links {
        height: 100%;
        .njc-flex-direction-row-center;
        border-right: 1px solid #10151D;

        &:last-child {
          border-right: 0px solid #10151D;
        }

        svg {
          .njc-sizing-sq(18px);
          color: var(--template-main-50);
        }
        img {
          display: none;
        }

        &.njc-template-save {
          svg {
            color: var(--template-main-50);
          }
        }
        &.njc-template-saving {
          .njc-temp-more-info-popup-wrap {
            display: flex !important;
            visibility: visible;
            opacity: 1;
            width: auto;
            height: auto;
            overflow: visible;
          }
          svg {
            color: var(--template-main-50);
          }
        }
        &.njc-template-saved {
          svg {
            color: var(--success-color);
          }
        }

        .njc-temp-more-info-popup-wrap {
          .njc-temp-more-info-popup;
          top: 45px;
        }
        &:hover {

          .njc-temp-more-info-popup-wrap {
            display: flex !important;
            visibility: visible;
            opacity: 1;
            width: auto;
            height: auto;
            overflow: visible;
          }
        }
      }
    }
  }

  .builder-wrap {
    margin-top: 40px;
    background-color: var(--template-canvas);
    .left-sidebar {
      background-color: var(--template-main-100);
      border-right: 1px solid var(--template-border);
      flex-basis: 280px;

      .left-sidebar-heading {
        margin: 0px;
        height: 48px;
        border-bottom: 1px solid #10151D;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-top: 2px;
        .njc-temp-left-panel-header;
      }

      .left-sidebar-overflow {
        max-height: calc(~"100% -" 140px);
        .njc-temp-scrollbars;
      }

      .image-title-wrap {
        width: 100%;
        position: relative;
        .njc-flex-direction-row !important;
        .njc-flex-startcenter;

        .page-image-wrap {
          height: 40px;
          padding-left: 16px;
          position: static;
          .njc-flex-direction-row;
          .njc-flex-startcenter;
          align-self: flex-start;

          .njc-widget-panel-icon {
            width: 18px;
            height: 18px;
            color: var(--template-main-30)
          }

          .handle {
            color: transparent;
            width: 280px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            cursor: pointer;
          }

          .page-number-text {
            width: 30px;
            height: 20px;
            border: 1px solid var(--template-main-30);
            color: var(--template-main-30);
            border-radius: 3px;
            padding: 0px;
            .njc-flex-direction-row-center;
            .njc-font-1;
            align-self: auto;
            font-size: 12px;
            font-weight: 700;
          }

          .page-image {
            display: none
          }

          .trash-wrap, .lock-wrap{
            margin: 0px 8px 0 0;
            height: 40px;
            .njc-flex-direction-row-center;
            align-self: flex-start;
            .njc-icon-button {
              height: 26px;
              svg {
                color: var(--grayscale-60);
              }
            }
          }
          .lock-wrap {
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 1003;
            width: 21px;
          }
        }

        &:hover .trash-wrap .njc-icon-button svg {
          display: block !important;
        }

        .page-title-text {
          padding: 10px 28px 10px 8px;
          .njc-flex-direction-row;
          .njc-flex-startcenter;

          .njc-temp-page-title-text {
            .njc-temp-left-panel-page;
            .njc-flex-startcenter;
            padding: 1px 2px;
            max-width: 199px;

            span {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          input {
            max-width: 0px;
            height: 22px;
            z-index: 2;
            padding: 0px;
            outline: none;
            background-color: var(--template-main-100);
            border-radius: 3px;
            .njc-temp-left-panel-page;
          }
          &.njc-temp-page-currently-edited {
            .njc-temp-page-title-text {
              display: none !important;
            }
            input {
              max-width: 199px;
              width: 199px;
              border: 1px solid var(--template-border);
              padding: 0 2px;
              margin-left: -2px;
              .njc-flex-direction-row;
            }
          }
        }
        &:hover {
          background-color: var(--template-hover);
        }

        &.selected {
          background-color: var(--template-hover);
          color: var(--template-main-5);
          box-shadow: inset 5px 0 0 0 var(--template-primary-color);

        }
      }

      .section-list-item-selected {
        .image-title-wrap {
          background-color: var(--template-contour);
          box-shadow: 4px 0 inset var(--template-primary-color);


        }
      }

      .add-page-flow-row {
        margin: 0px;
        .button-dark-gray {
          width: calc(~"100% -" 32px);
          .njc-temp-add-button;
        }
      }

      .njc-temp-left-help {
        padding-left: 16px;
        .njc-flex-direction-row;
        .njc-flex-startcenter;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 38px;
        width: 280px;
        color: var(--template-main-40);
        .njc-font-1;
        font-size: 12px;
        font-weight: 400;

        svg {
          .njc-sizing-sq(18px);
          margin-right: 6px;
        }

        &:hover {
          color: var(--template-main-20);
          cursor: pointer;
        }
      }
      
      // Widget Panel
      .njc-page-wrap-tabs {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        padding: 6px 13px 0;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--template-contour);
        width: 100%;
        .njc-page-wrap-tabs-content {
          flex: 1 0 50%;
          height: 32px;
          .njc-temp-property-wrap-tabs-text;
          .njc-flex-direction-row-center;
          cursor: pointer;
          color: var(--template-main-30);
          border-bottom: 3px solid transparent;

          svg {
            .njc-sizing-sq(16px);
            margin-right: 4px;
          }

          &.selected {
            color: var(--template-main-0);
            cursor: default;
            border-bottom: 3px solid var(--template-primary-color);
          }
        }
      }

      .njc-current-page-wrap {
        width: calc(~"100% -" 20px);
        padding: 0 8px 16px;
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--template-contour);
        .njc-temp-left-panel-page;

        a {
          margin-right: 4px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          svg {
            color: var(--template-main-5);
          }
        }
        .njc-current-page-label {
          font-weight: 600;
          color: var(--template-main-5);
          text-transform: uppercase;
        }
        .njc-current-page-name {
          font-weight: 400;
          color: var(--template-main-5);
        }
      }

      &:hover {
        .njc-menu-collapse-button {
          opacity: 1;
        }
      }
    }

    .center-area {
      .grid-underlay {
        .njc-shadow;
      }

      .transform-box {
        .msg-area {
          .message {
            .njc-font-1;
          }
        }
      }

      .support-bar {
        height: 40px;
        background-color: var(--template-main-10);
        border: 0px solid #fff;
        border-top: 1px solid var(--template-contour);
        padding: 0px;

        button {
          margin: 0px;
          height: auto;
          padding: 0 12px;
          border: 0px solid #fff;
          outline: none;
          background-color: var(--template-main-20);
          width: 125px;

          span {
            font-weight: 600;
            color: var(--template-main-100);
            .njc-flex-direction-row;
            .njc-flex-startcenter;

            svg {
              .njc-sizing-sq(18px);
              margin-right: 5px;
            }

            &.template-editor-snap-off {
              color: var(--template-main-50);
            }
          }
        }
      }
    }

    .right-sidebar {
      margin-top: -40px;
      max-width: 241px;

      .inner-row-container {
        background-color: transparent;
        .inner-row-container {
          padding-left: 0 !important;
        }
      }

      .panel-tabs-menu {
        height: 40px;
        min-height: 40px;
        background-color:var(--template-contour);
        padding-left: 0px;
        border-left: 1px solid var(--template-border);

        .panel-tab-link2 {
          padding: 0px;
          border: 0px;
          height: 100%;
          .njc-flex-direction-row-center;
          border-right: 1px solid var(--template-border);
          border-bottom: 1px solid var(--template-border);
          font-family: "Georgia", serif;
          background-color:var(--template-contour);


          .panel-tab-icon-wrap {
            position: relative;
          }
          .njc-temp-more-info-popup-wrap {
            .njc-temp-more-info-popup;
          }

          img {
            display: none;
          }

          svg {
            .njc-sizing-sq(18px);
            color: var(--template-main-10);
            margin: auto;
          }

          &.w--current {
            background-color: var(--template-main-100);
            border-bottom: 1px solid var(--template-main-100);
            color: var(--template-main-10);
            svg {
              color: var(--template-main-10);
            }
            &:hover {
              background-color: var(--template-main-100);
            }
          }

          &:first-child {
            .njc-temp-more-info-popup-wrap {
              left: 0;
              .njc-temp-more-info-popup-content {
                left: 0;
                &:before {
                  left: 4;
                  margin-left: 0;
                }
                &:after {
                  left: 5px;
                  margin-left: 0;
                }
              }
            }
          }
          &:last-child {
            .njc-temp-more-info-popup-wrap {
              left: auto;
              right: 0;
              .njc-temp-more-info-popup-content {
                left: auto;
                right: 0;
                &:before {
                  left: auto;
                  right: 4;
                  margin-left: 0;
                }
                &:after {
                  left: auto;
                  right: 5px;
                  margin-left: 0;
                }
              }
            }
          }

          &:hover {
            background-color: transparent;

            .njc-temp-more-info-popup-wrap {
              display: flex !important;
              visibility: visible;
              opacity: 1;
              width: auto;
              height: auto;
              overflow: visible;
            }
          }
        }

      }

      .w-tab-content {
        height: calc(~"100% -" 40px) !important;
        border-left: 1px solid var(--template-contour);

        .w-tab-pane {
          overflow-y: overlay;
          background-color: var(--template-main-100);
          position: static;

          .njc-temp-scrollbars;

          .ninja-date-range-string {
            .njc-temp-date-form;
          }

          .w-input {
            .njc-temp-input-form;
          }

          select {
            .njc-temp-dropdown-form;
          }

          .multi-select .select2-selection--multiple {
            .njc-temp-dropdown-form;

            .select2-search {
              margin: 0;
            }
            .select2-search__field {
              max-width: 190px;
              &::-webkit-search-cancel-button {
                -webkit-appearance: none;
                appearance: none;
                background-image: url(/images/njc/njc-small-close-grayscale-50.svg);
                width: 9px;
                height: 9px;
                margin-right: 10px;
              }
            }
          }

          .njc-form-toggle-default {
            .njc-temp-toggle-form;
          }

          .njc-right-widgets-title {
            padding: 16px 13px 8px;
            width: 100%;
            .njc-temp-right-panel-header;
          }

          .panel-search-wrap {
            padding: 0px 13px;
            margin: 0px 0px 8px 0px;

            input {
              .njc-temp-search-input;
            }
          }

          .panel-element-2 {
            .njc-temp-right-panel-accord-border;
            border-bottom: 0px solid transparent;

            .panel-head-text-2 {
              .njc-temp-right-panel-accord-1;
              padding: 0px;
            }

            .direction-arrow-wrap {
              margin: 0 0 0 auto;
              min-width: auto;
              .njc-flex-direction-row-center;

              img {
                display: none;
              }
            }


            &.inner-panel-element-2 {
              .njc-temp-right-panel-accord-border-2;

              .panel-head-text-2 {
                .njc-temp-right-panel-accord-2;
              }
            }

            &.direction-arrow-closed {
              .njc-temp-accordion-arrow {
                transition: 0.1s all;
                transform: rotate(-90deg);
              }
            }
            &.direction-arrow-open {
              .njc-temp-accordion-arrow {
                transition: 0.1s all;
                transform: rotate(0deg);
              }
            }
          }



          .panel-wrap-2 {
            padding: 0px;

            .hidden-layout-panel {
              padding: 0px;

              .element-row {
                margin: 0px;
                background-color: var(--template-main-100);

                .element-icon-wrap {
                  flex: 0 0 33.33%;
                  justify-content: center;
                  height: 94px;
                  border-right: 1px solid var(--template-contour);
                  border-bottom: 1px solid var(--template-contour);
                  padding: 5px;

                  .element-icon-image {
                    display: none;
                  }
                  svg {
                    .njc-sizing-sq(36px);
                    color: var(--template-main-30);
                    margin-top: 6px;
                  }
                  .element-icon-text {
                    .njc-temp-right-add-widget-text;
                    margin-top: @spacing-3;
                    min-height: 24px;
                    .njc-flex-direction-column-center;
                  }

                  &:hover {
                    background-color: var(--template-hover);

                  }
                }
              }

              .settings-properties-row {
                margin: 0px;
                background-color: transparent;
                border-bottom: 0px solid var(--template-border);
                padding-bottom: 0px;

                &.njc-temp-shape-widget {
                  padding-top: 12px;
                  .colorpicker-bit {
                    .njc-temp-color-select-wrap;
                    .njc-flex-direction-row;
                    .njc-flex-startcenter;

                    .njc-temp-styling-left-label {
                      width: 55px;
                      display: flex !important;
                      .njc-temp-right-panel-label-header;
                    }
                  }
                  .njc-temp-horizontal-align-wrap {
                    display: flex;
                    flex-direction: row;
                    margin: 4px 0;
                    .setting-properties-wrap {
                      display: flex;
                      flex-direction: row;
                      padding-right: 0px;
                      .property-label {
                        min-width: 55px;
                        align-items: center;
                      }
                      .w-input {
                        width: 38px;
                      }
                    }
                    .border-style-buttons {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      .border-style-button-single {
                        width: 53px;
                        height: 28px;
                        border: 1px solid var(--template-contour);
                        border-right: 0px;
                        background-color: var(--template-main-90);
                        color: var(--template-main-20);
                        cursor: pointer;
                        .njc-flex-direction-row-center;
    
                        &:first-child {
                          border-radius: 5px 0 0 5px;
                        }
                        &:last-child {
                          border-radius: 0 5px 5px 0;
                          border-right: 1px solid var(--template-contour);
                        }
                        &.selected {
                          background-color: var(--template-hover);
                        }
                        .border-style-button-single-icon {
                          width: 18px;
                          height: 1px;
                          border-width: 2px 0 0 0;
                          border-color: var(--template-main-20);
                        }
                      }
                    }
                  }
                  .njc-temp-widget-column-select-option {
                    margin-top: -12px;
                    margin-bottom: 12px;
                  }
                }
                &.njc-temp-right-properties-wrap {
                  padding: 8px 0;
                  .setting-properties-wrap {
                    border-bottom: 0px;
                  }
                  .font-style-bit {
                    .setting-properties-wrap {
                      padding-left: 0px !important;
                      padding-right: 0px !important;
                    }
                  }
                }

                .settings-properties-row {
                  .property-label {
                    padding: 8px 13px 2px;

                    & > div {
                      padding: 4px 0;
                      .njc-temp-right-panel-label-header;
                    }
                  }
                }

                .setting-properties-wrap {
                  background-color: transparent;
                  padding: 0px 13px;

                  .property-label {
                    padding: 4px 0;
                    .njc-temp-right-panel-label-header;

                    .njc-temp-right-add-metric-wrap {
                      position: relative;
                      cursor: pointer;

                      .njc-temp-right-add-widget-main-button {
                        color: var(--template-main-50);
                        z-index: 2;
                        padding-left: 2px;

                        &:hover {
                          color: var(--template-main-0);
                        }
                      }

                      svg {
                        width: 18px;
                        height: 16px;
                      }
                      .njc-temp-right-more-action-widget-button {
                        color: var(--template-main-50);
                        z-index: 2;
                        padding: 0 2px;
                        visibility: hidden;
                        width: 20px;

                        &:hover {
                          color: var(--template-main-0);
                        }
                      }

                      .njc-temp-right-add-metric-multi-wrap {
                        display: none;
                        position: absolute;
                        top: 5px;
                        right: 20px;
                        z-index: 3;
                        padding: 22px 3px 3px 3px;

                        .njc-temp-right-add-metric-multi-content {
                          .njc-temp-data-source-adding-popup;
                        }
                      }
                      .active {
                        display: flex;
                      }
                    }

                    .options-wrap {
                      margin: 0px;
                    }
                  }
                  .settings-panel-property-content {
                    margin: 4px 0;
                    padding: 0;
                    .njc-flex-direction-row;
                    .njc-flex-startcenter;
                    .njc-temp-right-panel-property-item;
                    background-color: transparent;
                    min-height: 26px;

                    .njc-property-content-buttons {
                      margin-left: auto;
                      visibility: hidden;
                      .njc-flex-direction-row-center;
                      .property-icon-wrap {
                        color: inherit;
                        min-width: 20px;
                        max-width: 20px;
                      }
                      svg {
                        .njc-sizing-sq(18px);
                        margin-left: 4px;
                        color: var(--template-main-50);
                      }
                    }

                    &:hover {
                      top: 0px;
                      cursor: default;
                      .njc-property-content-buttons {
                        visibility: visible;
                      }
                    }
                  }

                  .settings-panel-add-property-group {
                    .njc-flex-direction-column-start;
                  }

                  .add-property-button {
                    .njc-temp-add-button;
                    width: 100%;
                    .direction-arrow-wrap {
                      display: none;
                    }
                  }

                  &.njc-temp-widget-coverpage {
                    .setting-properties-wrap {
                      padding: 0px;
                      border-bottom: 0px;
                      .property-label {
                        padding: 4px 0 3px;
                      }
                      .settings-panel-property-content {
                        margin: 0 0 6px 0;
                        .inline-property-label {
                          display: none;
                        }
                        .setting-properties-wrap {
                          flex: 1;
                          .nc-ctx-drop-down-bit {
                            .njc-flex-direction-row;
                            .njc-flex-startcenter;
                            .njc-temp-styling-left-label {
                              width: 55px;
                              display: flex !important;
                              .njc-temp-right-panel-label-header;
                            }
                            .settings-panel-property-dropdown {
                              flex: 1;
                            }
                          }

                        }
                      }
                      .colorpicker-bit {
                        .njc-temp-color-select-wrap;
                        .njc-flex-direction-row;
                        .njc-flex-startcenter;

                        input[type="checkbox"] {
                          position: static !important;
                        }
                      }

                    }

                  }

                  &.njc-temp-widget-column-select-option {
                    padding: 0px;

                    .njc-temp-widget-column-select-option-content {
                      height: 56px;
                      .njc-flex-direction-column;
                      .njc-flex-justify;
                      .njc-temp-right-add-widget-text;

                      &-h1 , &-h2 , &-h3 , &-h4 , &-h5 , &-h6 {
                        font-family: "Georgia", serif;
                        font-weight: 700;
                        min-height: 36px;
                        color: var(--template-main-0);
                        .njc-flex-direction-row-center;
                        padding-bottom: 20px;
                      }
                      &-h1 {
                        font-size: 28px;
                      }
                      &-h2 {
                        font-size: 24px;
                      }
                      &-h3 {
                        font-size: 20px;
                      }
                      &-h4 {
                        font-size: 18px;
                      }
                      &-h5 {
                        font-size: 16px;
                      }
                      &-h6 {
                        font-size: 14px;
                      }
                    }
                  }
                }

                &.njc-temp-widget-image {
                  padding: 8px 13px 6px;
                  .setting-properties-wrap {
                    padding: 0px;
                    border-bottom: 0px;
                    .property-label {
                      padding: 4px 0 3px;
                    }
                    .settings-panel-property-content {
                      margin: 0 0 6px 0;
                      .inline-property-label {
                        display: none;
                      }
                      .setting-properties-wrap {
                        flex: 1;
                        .nc-ctx-drop-down-bit {
                          .njc-flex-direction-row;
                          .njc-flex-startcenter;
                          .njc-temp-styling-left-label {
                            width: 55px;
                            display: flex !important;
                            .njc-temp-right-panel-label-header;
                          }
                          .settings-panel-property-dropdown {
                            flex: 1;
                          }
                        }

                      }
                    }
                    .colorpicker-bit {
                      .njc-temp-color-select-wrap;
                      .njc-flex-direction-row;
                      .njc-flex-startcenter;

                      input[type="checkbox"] {
                        position: static !important;
                      }
                    }

                  }

                  .image-url-bit {
                    .njc-flex-direction-row;
                    .njc-flex-justify;

                    .settings-panel-property-content {
                      margin: 0px;
                      padding: 0px;

                      &:first-child {
                        flex: 1;
                      }

                      input[type="text"] {
                        margin-right: 8px;
                      }
                      .image-url-button {
                        margin-bottom: 4px;
                        .njc-temp-right-panel-property-item;
                        text-transform: lowercase;
                        &:first-letter {
                          text-transform: capitalize;
                        }
                      }
                    }
                  }
                }

                &.njc-temp-right-data-row {
                  border-bottom: 1px solid var(--template-border);
                  .setting-properties-wrap {
                    padding: 8px 13px 10px;
                    .settings-panel-property-content {
                      .njc-temp-right-panel-property-item-border;
                      .njc-temp-right-panel-property-item-border-selected;

                      &.njc-temp-right-item-warning {
                        background-color: var(--critical-color) !important;
                        color: var(--template-main-0);



                        .property-icon-wrap {
                          order: 1;

                          &.njc-temp-warning-icon {
                            order: 2;

                            &:hover {
                              .njc-temp-more-info-popup-wrap {
                                .njc-temp-more-info-popup;
                                display: flex !important;
                                left: auto;
                                right: -2px;

                                .njc-temp-more-info-popup-content {
                                  left: auto;
                                  &:before {
                                    left: auto;
                                    right: 1px;
                                  }
                                  &:after {
                                    left: auto;
                                    right: 2px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &:hover .njc-temp-right-more-action-widget-button {
                    visibility: visible !important;
                  }
                }
                .njc-temp-right-empty-widget {
                  .njc-temp-right-empty-widget-message;
                  width: auto;
                  margin: 10px 13px;
                }
              }

              .element-widget-row {
                border: 0px solid var(--template-contour);;
                border-bottom: 1px solid var(--template-contour);
                background-color: var(--template-main-100);
                min-height: 40px;
                padding: 6px 0px;

                .left-element-widget-wrap {
                  flex-basis: 39px;
                  .element-widget-icon-wrap {
                    margin: 0 0 0 13px;
                    svg {
                      color: var(--template-main-0);
                    }
                  }
                }
                .center-element-widget-wrap {
                  padding-left: 8px;
                }

                .element-widget-text {
                  .njc-temp-right-add-widget-text;
                }

                .element-widget-icon-wrap {
                  margin-right: 13px;

                  .element-widget-image {
                    display: none;
                  }
                }

                &:hover {
                  background-color: var(--template-hover);
                  cursor: pointer !important;
                  .njc-icon-button svg {
                    display: block !important;
                  }
                }
              }

              .njc-template-aggregate {
                padding: 4px 12px;
              }
              .property-tab {
                padding: 0px 13px;
                display: flex;

                .w-form {
                  width: 100%;
                  .w-tabs {
                    margin: 0 -13px;
                    width: calc(~"100% +" 26px);

                    .w-tab-menu {
                      border-left: 0px;
                      background-color: transparent;
                      border-top: 1px solid var(--template-border);

                      .panel-tab-link2 {
                        background-color: var(--template-hover);
                        border-bottom: 1px solid var(--template-border);
                        border-right: 1px solid var(--template-border);
                        &.w--current {
                          background-color: transparent;
                          border-bottom: 1px solid transparent;
                        }
                        &:last-child {
                          border-right: 0px;
                        }
                      }
                    }

                    .njc-temp-right-tab-title {
                      .njc-temp-tab-title-text;
                    }

                    .w-tab-content {
                      background-color: transparent !important;
                      .settings-properties-row {
                        border-bottom: 0px;

                      }
                    }
                  }
                }
              }

              .njc-template-aggregate , .property-tab {
                .metric-row {
                  .njc-temp-right-panel-sub-header;
                  margin: 4px 0;
                  padding: 0px;
                  background-color: var(--template-main-100);
                  padding: 0px;
                  border: 0px solid #fff;

                  &.no-hover {
                    cursor: default;
                    .njc-temp-right-panel-accord-2;
                    .njc-temp-right-panel-accord-border-2;
                    align-items: center;
                    margin: -4px -13px 0;
                  }
                }

                .settings-properties-row {

                  &.SORT_BY , &.ACTION_ATTRIBUTION_WINDOWS {
                    .njc-flex-direction-column;

                    .option-item {
                      width: 100%;
                    }
                  }

                  &.FORCE_UNIQUE , &.zeroImpressionReporting {
                    flex-direction: row;

                    .option-item {
                      order: 1;
                      margin-right: 8px;
                    }
                    .setting-properties-wrap {
                      order: 2;
                      .njc-flex-direction-row;
                      .njc-flex-startcenter;

                    }
                  }

                  &:last-child {
                    border-bottom: 0px solid #fff;
                  }
                }

                .setting-properties-wrap {
                  padding: 0px;
                  border-bottom: 0px;

                  .settings-panel-property-content {
                    padding: 0px;
                    background-color: transparent !important;
                  }
                }

                .gray-back.property-label {
                  padding: 4px 0px;
                  background-color: transparent;
                  .njc-temp-right-panel-label-header;
                }
              }

              .font-style-bit {
                .setting-properties-wrap {
                  padding: 8px 13px 6px;
                  .setting-properties-wrap {
                    padding: 0px;
                    border-bottom: 0px;

                    .settings-panel-property-content {
                      padding: 0px;
                      background-color: transparent !important;
                    }
                  }
                }

                &.njc-temp-border-wrap {
                  .njc-temp-styling-wrap {
                    .colorpicker-bit {
                      order: 1;
                      flex: none;
                    }
                    .font-size-wrap {
                      margin-bottom: 6px;
                      z-index: 1;
                      &:after {
                        content: "px";
                        height: 26px;
                        width: 20px;
                        text-transform: uppercase;
                        z-index: 2;
                        margin-left: -25px;
                        color: var(--template-main-20);
                        .njc-flex-direction-row-center;
                      }
                      input[type="number"] {
                        padding-right: 30px;
                      }
                    }
                  }

                }
              }

              .nc-template-builder-modal {
                .Modal {
                  z-index: 1010;
                  background-color: rgba(45,53,64,0.9);
                  .Modal__container {
                    padding: 0px;
                    width: 500px;
                    border-radius: 8px;
                    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
                    .Modal__content {
                      padding: 0px;

                      .property-label {
                        padding: 20px 0 0 0;
                        color: var(--grayscale-50);
                        font-weight: 600;
                        font-size: 14px;
                      }
                      .setting-properties-wrap {
                        padding: 0;
                        border: 0px;
                        .property-label {
                          .njc-text-form-label;
                        }
                        .settings-panel-property-content {
                          margin: 8px 0 0 0;
                          input[type="text"] , input[type="number"] , select {
                            .njc-text-form;
                            .njc-text-form-border;
                            background-color: var(--grayscale-0);
                            line-height: 16px;
                          }
                          select {
                            .njc-text-form-select;
                          }

                          &.abbrev-selection {
                            margin: 8px 8px 0 0;
                          }
                        }

                      }
                      .njc-temp-modal-buttons-wrap {
                        justify-content: flex-end !important;
                        .button-blue {
                          .njc-text-link;
                          cursor: pointer;
                          border: 0px;
                          background-color: transparent;
                          text-transform: capitalize;
                          padding: 10px 0px 0px 20px;
                        }
                      }
                      .njc-form-footer-row {
                        padding: 28px 0 0 0;
                        .njc-form-footer-row-container {
                          border: 0px;
                          padding: 0px;
                          margin: 0px;
                        }
                      }

                      .njc-temp-modal-dimension-wrap {
                        max-height: none !important;
                        overflow-y: visible !important;
                        .njc-temp-modal-find-replace-wrap {

                          .njc-temp-heading-3 {
                            .njc-heading-3;
                          }
                          .setting-properties-wrap {
                            .njc-flex-direction-column;

                            .replacement-overflow {
                              overflow-y: auto;
                              max-height: 250px;
                              .njc-temp-scrollbars;
                            }

                            .njc-temp-modal-find-replace-entry-wrap {
                              .njc-temp-modal-find-replace-entry;
                            }
                            .njc-temp-modal-find-replace-nodata {
                              color: var(--grayscale-30);

                            }
                            .njc-temp-modal-find-replace-add {
                              border-top: 1px solid var(--grayscale-30);
                              padding-top: 10px;
                              margin-top: 10px;
                              button {
                                .njc-btn-dashed-add !important;

                              }
                            }
                          }
                          hr {
                            display: none;
                          }
                        }
                      }

                      .abbrev-description {
                        .njc-temp-abbreviation;
                        margin: 0px;
                        .abbrev-description-title , .abbrev-description-item {
                          color: var(--grayscale-80);
                        }
                      }

                      .filter-and-clause {
                        .filter-or-clause {
                          .njc-flex-direction-row;
                          flex-wrap: wrap;

                          .setting-properties-wrap {
                            width: 100%;
                            &:last-child {
                              min-width: 60px;

                              .settings-panel-property-content {
                                color: var(--grayscale-80);
                                & > span {
                                  padding-right: 8px !important;
                                  input {
                                    margin: 0 4px;
                                    min-width: 60px;
                                  }
                                  input[type="checkbox"] {
                                    min-width: 20px;
                                  }
                                }
                                span:last-child {
                                  .njc-flex-direction-row;
                                  .njc-flex-startcenter;
                                  b {
                                    padding-right: 4px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      .card-footer-row {
                        flex-direction: row;
                        padding-bottom: 0px;
                        border-top: 0px solid #FFFFFF;
                        margin: 0 -40px;
                        padding: 20px 40px 0px;
                        justify-content: flex-end;
                        .njc-btn-primary , .njc-btn-secondary {
                          margin-left: 12px;
                          font-size: 14px;
                          padding: 0px 20px;
                        }
                      }

                      .njc-custom-dimension-name-row-wrap {
                        flex-wrap: wrap;
                        .njc-custom-dimension-name-option {
                          order: 1;
                          width: 60%;
                          padding-right: 10px;
                        }
                        .njc-custom-dimension-hide-option {
                          order: 3;
                          .settings-panel-property-content {
                            margin: 13px 0 0 0;
                          }
                          .nc-toggle-switch {
                            .nc-slider {
                              &:after {
                                content: "Hide";
                              }
                            }
                          }
                        }
                        .njc-custom-dimension-non-segmenting-option {
                          order: 4;
                          .settings-panel-property-content {
                            margin: 0px;
                          }
                          .nc-toggle-switch {
                            .nc-slider {
                              &:after {
                                content: "Non Segmenting";
                              }
                            }
                          }
                        }
                        .njc-custom-dimension-hide-option , .njc-custom-dimension-non-segmenting-option {
                          width: 100%;
                          .nc-toggle-switch {
                            input {
                              display: none;
                              &:checked + .nc-slider {
                                background-color: var(--primary-color);
                                border: 1px solid var(--primary-color);
                                background-image: url(/images/njc/njc-checkbox.svg);

                              }
                            }
                            .nc-slider {
                              .njc-sizing-sq(18px);
                              border-radius: 5px;
                              border: 1px solid var(--grayscale-30);
                              background-color: var(--grayscale-0);
                              background-repeat: no-repeat;
                              background-position: 3px 50%;

                              &:before {
                                display: none;
                              }
                              &:after {
                                margin-left: 25px;
                                white-space: nowrap;
                                .njc-text-paragraph;
                              }
                            }
                          }
                        }
                        .njc-custom-dimension-display-option {
                          order: 2;
                          width: 40%;
                        }
                      }

                      .njc-custom-dimension-aggregate-row-wrap {
                        .property-label {
                          .njc-text-heading-3;
                        }
                        .custom-dimension-body-aggregate {
                          margin: 0px;
                          max-height: none;

                          .table-column-shrink {
                            padding: 30px 5px 15px 0 !important;
                            border-bottom: 1px solid var(--grayscale-30);
                            min-width: 140px;
                            text-align: left;
                            .njc-text-table-header;
                          }
                          .table-column-expand {
                            padding: 15px 0 !important;
                            border-bottom: 1px solid var(--grayscale-30);
                            .setting-properties-wrap {
                              border: 0px solid var(--grayscale-30) !important;
                              .select2 {
                                .select2-selection {
                                  min-height: 44px !important;
                                  border-radius: 5px !important;
                                }
                              }

                              .replacement-overflow {
                                .njc-temp-modal-find-replace-entry-wrap {
                                  .njc-temp-modal-find-replace-entry;
                                }
                              }

                              .njc-temp-modal-find-replace-add {
                                margin-top: 10px;
                                button {
                                  .njc-btn-dashed-add !important;

                                }
                              }
                            }
                          }
                          .custom-dimension-table-aggregate {
                            border-collapse: collapse;
                            tr:last-child {
                              .table-column-shrink , .table-column-expand {
                                border-bottom: 0px solid #fff;
                              }
                            }
                          }
                        }
                      }
                      .nc-date-range-error-text {
                        margin: 0px;
                      }
                    }
                  }
                }

                &.custom-dimension-edit-modal {
                  .Modal__container {
                    max-height: none !important;
                  }

                }

                &.njc-temp-custom-metric-editor {
                  .Modal .Modal__container {
                    max-height: none !important;
                    margin-bottom: 30px;
                    .njc-temp-scrollbars;

                    .Modal__content {
                      .njc-flex-direction-column;
                    }
                  }
                  .njc-temp-custom-metric-editor-row-1 {
                    .njc-flex-direction-row;
                    .njc-flex-startcenter;
                    flex-wrap: wrap;
                    order: 1;

                    .setting-properties-wrap {
                      width: 100%;
                    }
                    .njc-temp-custom-metric-editor-toggle {
                      .property-label {
                        display: none;
                      }
                      .nc-toggle-switch {
                        input[type="checkbox"] {
                          display: block;
                          margin: 0px;
                          .njc-checkbox-input;

                          &:after {
                            content: "Hide Metric";
                            margin-left: 24px;
                            white-space: nowrap;
                            .njc-checkbox-label;
                            .njc-text-paragraph;
                          }
                        }
                        .nc-slider {
                          display: none;
                        }
                      }
                    }
                  }
                  .njc-temp-custom-metric-editor-row-2 {
                    .njc-flex-direction-row;
                    .njc-flex-startcenter;
                    flex-wrap: wrap;
                    order: 2;

                    .setting-properties-wrap {
                      &:first-child {
                        .settings-panel-property-content {
                          margin: 8px 8px 0 0;
                        }
                      }
                    }
                  }

                  .abbrev-description {
                    .njc-flex-direction-column;
                    .njc-flex-centerstart;
                    margin: 8px 0 0 12px !important;

                    .abbrev-description-title {
                      .njc-text-paragraph-light !important;
                    }
                    .abbrev-description-item {
                      .njc-text-paragraph !important;
                    }
                  }

                  .njc-temp-custom-metric-editor-row-3 {
                    order: 4;
                    .property-label {
                      margin-right: 0px;
                      padding-top: 48px !important;
                    }
                    .njc-temp-custom-metric-editor-heading-3 {
                      .njc-text-heading-3;
                    }
                    .njc-temp-custom-metric-editor-formula-wrap {
                      .njc-flex-direction-row;

                      select {
                        background-color: var(--grayscale-10);
                        color: var(--grayscale-100);
                        background-image: url(/images/njc/njc-icon-dropdown.svg);
                        height: 30px;
                        margin-left: 10px;
                        border: 0px solid var(--grayscale-0);
                        .njc-text-paragraph;
                        padding: 0 14px;
                      }
                    }
                    textarea.njc-form-input {
                      height: 180px;
                      font-size: 14px;
                      padding: 12px 20px;
                      line-height: 18px;
                      color: var(--grayscale-100);
                      border: 1px solid var(--grayscale-30);
                      background-color: var(--grayscale-0);
                      .njc-temp-scrollbars;
                    }
                    .njc-temp-custom-metric-editor-valid-msg {
                      margin-top: 8px;
                      text-align: left;
                      .njc-text-paragraph;
                    }
                    .njc-temp-custom-metric-editor-valid-msg-error {
                      .njc-text-paragraph-bold;
                      color: var(--critical-color);
                    }
                  }
                  .njc-temp-custom-metric-editor-row-4 {
                    min-height: auto !important;
                    max-height: none !important;
                    order: 3;

                    .settings-panel-property-content {
                      .njc-flex-direction-column-start;
                    }
                    .njc-temp-custom-metric-editor-metricselect-wrap {
                      min-width: 100% !important;
                      max-width: 100% !important;

                      .njc-temp-custom-metric-editor-metricselect-3column , .njc-temp-custom-metric-editor-metricselect-group{
                        .item-picker {
                          height: 240px;
                          .njc-temp-scrollbars;
                          background-image: none !important;
                          margin-right: 8px;
                          padding: 0px !important;
                          overflow-y: overlay;

                          &:last-child {
                            margin-right: 0px;
                          }

                          option {
                            padding: 5px 16px;
                            min-height: 28px;
                            white-space: normal;
                            width: 100%;
                            .njc-flex-direction-row;
                            .njc-flex-startcenter;
                            .njc-text-form;
                          }
                          optgroup {
                            padding: 5px 16px 0px;
                            margin-top: 5px;
                            min-height: 28px;
                            white-space: normal;
                            .njc-flex-direction-column;
                            .njc-flex-centerstart;
                          }
                        }
                      }
                    }
                  }
                  .custom-metric-choice {
                    min-height: 40px !important;
                    max-height: 40px !important;
                    margin: 8px 0 0 0;
                    width: 100%;
                    .njc-flex-direction-row;
                    .njc-flex-startcenter;
                    h5 , h4 {
                      .njc-text-paragraph-bold;
                      white-space: nowrap;
                      margin: 0;
                      text-transform: capitalize;
                      span {
                        display: inline !important;
                      }
                    }
                    p {
                      .njc-text-paragraph;
                      white-space: nowrap;
                      margin: 0;
                      padding-left: 6px;
                    }
                  }
                  .njc-form-footer-row {
                    order: 5;
                  }
                }
              }
            }

            .horiz-element-button-wrap {
              .njc-temp-right-panel-accord-border;

              .element-image-wrap {
                display: none;
              }

              .element-button-icon-text-2 {
                .njc-temp-right-panel-accord-1;

              }
            }
          }

          .njc-property-wrap {
            position: relative;
            padding: 6px 13px 0;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--template-contour);
            .njc-property-wrap-tabs {

              .njc-flex-direction-row-center;

              .njc-property-wrap-tabs-content {
                flex: 1 0 50%;
                height: 32px;
                .njc-temp-property-wrap-tabs-text;
                .njc-flex-direction-row-center;
                cursor: pointer;
                color: var(--template-main-30);
                border-bottom: 3px solid transparent;

                svg {
                  .njc-sizing-sq(16px);
                  margin-right: 4px;
                }

                &.selected {
                  color: var(--template-main-0);
                  cursor: default;
                  border-bottom: 3px solid var(--template-primary-color);
                }

              }
            }

            .panel-element-2 {
              display: none;
              &.inner-panel-element-2 {
                display: flex;
              }
            }

            .njc-temp-right-global-properties-wrap , .njc-temp-right-global-styling-wrap {
              position: absolute;
              top: 55px;
              transition: left 0.2s;
              overflow: hidden;
              max-height: 600px;

              &.selected {
                display: flex;
                left: 0;
                border-bottom: 0px;
                max-height: none;
              }

            }
            .njc-temp-right-global-properties-wrap {
              left: -100%;
              margin-top: -10px !important;
              .setting-properties-wrap {
                border-bottom: 0px !important;
              }
            }
            .njc-temp-right-global-styling-wrap {
              left: 100%;
            }

            .settings-properties-row {
              padding-bottom: @spacing-0 !important;
              .settings-properties-row {
                padding-bottom: @spacing-2 !important;
                .settings-properties-row {
                  padding-bottom: @spacing-0 !important;
                }

                .settings-panel-property-content {
                  padding: @spacing-0 !important;
                  border-bottom: 0px;
                  background-color: transparent !important;

                  .ninja-date-range-string {
                    .njc-temp-date-form;
                  }

                  .w-input {
                    .njc-temp-input-form;
                  }

                  select {
                    .njc-temp-dropdown-form;
                  }

                  .njc-form-toggle-default {
                    .njc-temp-toggle-form;
                  }
                }
                .abbrev-description {
                  .njc-temp-abbreviation;
                }

              }

              .property-label {
                .njc-temp-right-panel-label-header;
              }
            }
          }

          .panel-title-wrap {
            border: 0px solid #fff;
            padding: 0px;
            background-color: transparent;
            .njc-temp-right-empty-widget {
              .njc-temp-right-empty-widget-message;
            }
          }

          .njc-temp-right-global-styling-wrap {
            .njc-temp-right-color-select-only {

              .setting-properties-wrap {
                .njc-flex-direction-column-start;

                .nc-row-item {
                  .colorpicker-bit {

                    .sp-replacer {
                      .njc-temp-color-select;
                    }

                    img {
                      display: none;
                    }
                  }
                }
              }
            }
            .inner-row-container {
              .panel-wrap-2 {
                .hidden-layout-panel {
                  .settings-properties-row {
                    .settings-properties-row {
                      border-bottom: 0px;
                      .setting-properties-wrap {
                        .njc-temp-styling-wrap {
                          .setting-properties-wrap {
                            padding: 0px !important;
                            border-bottom: 0px;
                          }
                        }
                      }
                    }
                  }
                }

              }
            }
          }
          &.widgets-menu , &.premade-widgets-menu {
            .panel-element-2 {
              height: 28px;
              .panel-head-text-2 {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }

          .njc-temp-right-text-select-instruction {
            .njc-temp-right-empty-widget-message;
            margin: 8px 13px;
            width: auto;
          }

          .legacy-kpi-widget-menu {
            .setting-properties-wrap {
              .setting-properties-wrap {
                border-bottom: 0px solid transparent !important;
              }
            }
          }

          .panel-wrap-2 {
            .njc-temp-right-override-wrap {
              .property-label {
                padding: 4px 0px 0 0 !important;
                .setting-panel-icon-wrap {
                  width: 100%;
                  padding: 4px 0px 0 0 !important;
                  .njc-temp-right-override {

                    &.njc-notoverriden {
                      background-color: var(--template-main-90);
                      margin-bottom: 4px;
                      .njc-temp-right-override-state {
                        color: var(--template-main-30);
                      }
                    }
                    &.njc-overriden {
                      background-color: var(--template-primary-color);
                      .njc-temp-right-override-state {
                        color: var(--template-primary-color-5);
                      }
                    }

                    .njc-temp-right-override-content {
                      .njc-flex-direction-row;
                      .njc-flex-justify;
                      cursor: pointer;
                      width: 100%;
                      min-height: 28px;
                      padding: 4px 13px;

                      .njc-temp-right-override-text {
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--template-main-10);
                        text-align: left;
                        padding-right: 20px;
                      }
                      .njc-temp-right-override-state {
                        font-size: 11px;
                        font-weight: 800;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
              .settings-properties-row , .setting-properties-wrap {
                border-bottom: 0px !important;
              }
            }
          }
          .njc-temp-widget-column-select-option {
            margin: 0px;
            .element-icon-wrap.selected {
              background-color: var(--template-contour);
            }
            .arrow-preview {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 10px;
              gap: 4px;

              svg {
                width: auto !important;
                height: auto !important;
                color: inherit !important;
                margin-top: 0px !important;
                font-size: 14px;
              }
            }
          }
        }

        .font-style-bit {
          .setting-properties-wrap {
            .njc-temp-styling-wrap {
              flex-wrap: wrap;
              margin: 0px;
              padding: 0px;
              .setting-properties-wrap {
                flex: 1 0 100% !important;
                margin: 0 0 6px 0 !important;
                .nc-ctx-drop-down-bit {
                  .njc-flex-direction-row;
                  .njc-flex-startcenter;
                  width: 100%;

                  .njc-temp-styling-left-label {
                    width: 55px;
                    display: flex !important;
                    .njc-temp-right-panel-label-header;
                  }
                  select {
                    flex: 1;
                  }
                }
              }

              .font-size-wrap {
                order: 1;
                min-width: 110px;
              }
              .option-picker {
                order: 2;
                width: 40px;
                height: 26px;
                margin: 0 0 6px 6px;
                background-color: var(--template-main-90) !important;
                border: 1px solid var(--template-contour) !important;
                color: var(--template-main-20) !important;
                border-radius: 3px;
                font-size: 12px;
                opacity: 1 !important;
                .njc-font-1;
                overflow: hidden;
                text-indent: 100px;

                &.selected {
                  background-color: var(--template-hover) !important;
                }

                svg {
                  .njc-sizing-sq(18px);
                  position: absolute;
                }

                img {
                  display: none;
                }
              }
              .colorpicker-bit {
                order: 3;
                flex: 1 0 50%;
                margin-bottom: 6px;
                .njc-temp-color-select-wrap;
              }

              .njc-temp-right-text-alignment-wrap {
                margin-left: 0px !important;
                margin-bottom: 6px !important;
                margin-right: 0px;
                .njc-flex-direction-row;
                .njc-flex-startcenter;

                .njc-temp-styling-left-label {
                  width: 55px;
                  display: flex !important;
                  .njc-temp-right-panel-label-header;
                }
                .njc-temp-right-text-alignment-inner {
                  .njc-flex-direction-row;
                  .njc-flex-startcenter;

                  .njc-temp-right-text-alignment-item {
                    width: 40px;
                    height: 28px;
                    border: 1px solid var(--template-contour);
                    border-right: 0px;
                    background-color: var(--template-main-90);
                    color: var(--template-main-20);
                    cursor: pointer;
                    .njc-flex-direction-row-center;

                    &:first-child {
                      border-radius: 5px 0 0 5px;
                    }
                    &:last-child {
                      border-radius: 0 5px 5px 0;
                      border-right: 1px solid var(--template-contour);
                    }
                    &.selected {
                      background-color: var(--template-hover);
                    }
                    svg {
                      .njc-sizing-sq(18px);
                    }
                  }
                }

              }
            }

          }

        }

        .colorpicker-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 6px;
          .colorpicker-bit {
            .njc-temp-color-select-wrap;
          }
          &.label-60 {
            .njc-temp-styling-left-label {
              width: 60px !important;
            }
          }
          &.label-70 {
            .njc-temp-styling-left-label {
              width: 70px !important;
            }
          }
          &.label-80 {
            .njc-temp-styling-left-label {
              width: 80px !important;
            }
          }
        }

        &.njc-temp-intab-mixed {
          padding: 0 13px;
          .font-style-bit {
            .setting-properties-wrap {
              padding-left: 0px !important;
              padding-right: 0px !important;
            }
          }
          .settings-properties-row {
            .property-label {
              padding: 4px 0 !important;
              .njc-temp-right-panel-label-header;
            }
            .settings-panel-property-content {
              margin: 0px;
            }
            .options-wrap {
              margin: 0px;
              padding-bottom: 4px;
            }
            .njc-temp-right-override-wrap {
              margin: 0 -13px;
            }
          }
        }
      }


      .metric-picker-panel {
        width: 241px;
        height: 100%;
        overflow-y: visible;
        left: auto;
        background-color: var(--template-main-100);
        
        .njc-temp-adding-panel-top-row {
          height: 50px;
          .njc-flex-direction-row;
          .njc-flex-startcenter;
          padding: 0px 13px;
          .njc-temp-left-panel-header;
          border-bottom: 1px solid var(--template-contour);
          position: sticky;
          top: 0;
          background-color: var(--template-main-100);

          svg {
            .njc-sizing-sq(18px);
            color: var(--template-main-60);
            margin-right: 13px;
          }
        }
        .panel-search-wrap {
          position: sticky;
          top: 50px;
          background-color: var(--template-main-100);
          padding: 8px !important;
          margin: 0px !important;
        }

        .horiz-element-row {
          padding: 0px;
          height: calc(~"100% - 40px");
          overflow-y: auto;
          .njc-temp-scrollbars;

          .hidden-picker-metrics {
            padding: 0px;
            .metric-row {
              padding: 0px 13px;
              min-height: 30px;
              background-color: transparent;
              .njc-flex-startcenter;
              border-bottom: 0px solid var(--template-contour);
              .njc-temp-right-panel-label-result;

              &:hover, &.active {
                background-color: var(--template-hover);
              }
            }

            .metrics-list {
              padding: 8px 13px 10px;
              margin: 0;
            }

            &.njc-temp-right-metric-selected {
              position: sticky;
              top: 0;
              background-color: var(--template-main-100);
              margin-bottom: 10px;
              max-height: 295px;
              overflow-y: auto;
              .njc-temp-scrollbars;
              .metric-row {
                .njc-temp-right-panel-accord-border;
                .njc-temp-right-panel-accord-border-2;
                .njc-temp-right-panel-accord-2;
                padding: 0 8px 0 13px;
                .direction-arrow-wrap {
                  margin: 0 0 0 auto;
                  img {
                    display: none;
                  }
                }
              }
              .metric-list-item {
                .njc-temp-right-panel-property-item;
                .njc-temp-right-panel-property-item-border;
                .njc-temp-right-panel-property-item-border-selected;
              }
            }
            &.njc-temp-right-metric-available {

              & > div.metric-row {
                .njc-temp-right-panel-accord-border;
                .njc-temp-right-panel-accord-border-2;
                .njc-temp-right-panel-accord-2;
                padding: 0 8px 0 13px;
                .direction-arrow-wrap {
                  margin: 0 0 0 auto;
                  img {
                    display: none;
                  }
                }
              }

              .hidden-sub-picker-metrics {
                padding: 8px 13px 10px;
                .metric-row {
                  .njc-temp-right-panel-property-item;
                  .njc-temp-right-panel-property-item-border;
                  .njc-temp-right-panel-property-item-border-available;

                  .njc-temp-right-selected-check {
                    margin-right: 0px;
                    margin-left: auto;
                  }
                }
              }
            }


            .njc-temp-right-selected-check {
              .njc-sizing-sq(12px);
              min-width: 12px;
              margin-right: 4px;
            }

            .njc-temp-right-selected-close {
              .njc-sizing-sq(12px);
              min-width: 12px;
              margin-left: auto;
            }
          }
        }

        .njc-temp-scrollbars;
      }

      .njc-temp-right-override-options-wrap {
        border-left: 2px solid var(--template-primary-color);
        .series-color-wrap {
          margin: 0px 13px 4px;
        }
      }

      .nc-template-builder-modal {
        &.nc-quick-date-range , &.mapping-changes-modal {
          & * {
            .njc-font-1;
          }


          .Modal {
            z-index: 1010;
            background-color: rgba(45, 53, 64, 0.9);
            .Modal__container {
              width: 600px !important;
              padding: 0px;

              .Modal__content {
                padding: 0px;

                .njc-tertiary-tabs-link {
                  color: var(--grayscale-100) !important;
                  border-bottom-width: 4px;
                  .njc-font-1;
                  text-transform: capitalize;
                  &:hover {
                    border-bottom-width: 4px;
                    border-bottom-color: transparent;
                    background-color: transparent;
                  }
                  &.njc-tertiary-tabs-link-current {
                    border-bottom-color: var(--critical-color);
                  }
                }

                .njc-date-range-container {
                  border: 1px solid var(--grayscale-20);
                  border-radius: 5px;
                  height: 350px;
                  margin: 0px;
                  .njc-flex-direction-row;
                  .njc-flex-startcenter;
                  flex-wrap: wrap;
                  overflow-y: auto;

                  div {
                    width: 50%;
                    min-height: 24px;
                    padding: @spacing-0 @spacing-4;
                    color: var(--grayscale-100);
                    font-weight: 400;
                    .njc-flex-direction-row;
                    .njc-flex-startcenter;
                    &:hover {
                      color: var(--primary-color);
                      cursor: pointer;
                    }
                  }

                  .nc-date-range-selected {
                    color: var(--primary-color);
                    background-color: var(--grayscale-10);
                  }
                }
                .njc-date-range-relative {
                  margin: 0;
                  label {
                    .njc-font-1;
                    margin-bottom: 0px;
                  }
                  .njc-checkbox {
                    label {
                      font-size: 13px;
                      color: var(--grayscale-100);
                      font-weight: 400;
                    }
                  }
                  .njc-date-range-box-wrap {
                    .njc-date-range-box1 {
                      width: 84px;
                      line-height: 16px;
                    }
                    .njc-date-range-box2 {
                      width: 121px;
                      line-height: 16px;
                    }
                  }
                  .nc-help-text {
                    color: var(--grayscale-50);
                  }
                  .form-field-row {
                    padding: 0px;
                    .expand.r-pad-30 {
                      padding: 0px;
                    }
                    .njc-date-range-label {
                      .njc-text-form-label;
                      margin-bottom: @spacing-2;
                      display: inline-block;
                    }

                    .njc-checkbox {
                      margin-bottom: @spacing-2;
                    }

                    .njc-date-range-box-wrap {
                      .njc-flex-direction-row;
                      .njc-flex-startcenter;
                      margin-bottom: @spacing-2;
                    }

                    .njc-date-range-box1 {
                      .njc-text-form;
                      .njc-text-form-border;
                      margin-right: @spacing-2;
                    }

                    .njc-date-range-box2 {
                      .njc-text-form;
                      .njc-text-form-border;
                      .njc-text-form-select;
                      margin-right: @spacing-2;

                      &.njc-date-range-box2-last {
                        margin-left: @spacing-2;
                        margin-right: @spacing-0;
                      }
                    }

                    .njc-date-range-box3 {
                      .njc-text-form;
                      .njc-text-form-border;
                      .njc-text-form-calendar;
                    }

                    .njc-date-range-helptext {
                      .nc-help-text {
                        padding: @spacing-0;
                        margin: @spacing-0;
                        background-color: transparent;
                      }

                      .njc-form-description {
                        flex-basis: 100%;
                        max-width: 100%;
                        padding-left: @spacing-0;
                        margin-left: @spacing-0;
                        .njc-text-form-sublabel;

                        .nc-help-text {
                          min-height: 30px;
                        }
                      }
                    }
                  }

                  .njc-form-text-default {
                    padding-top: @spacing-0;
                  }
                }
                .w-input {
                  background-color: var(--grayscale-0);
                }

              }
            }
          }

          .b-pad-15.t-pad-10.b-line {
            padding: 0px;
            border-bottom: 0px;
          }
        }
      }

      .image-upload-bit {
        .njc-temp-upload-image !important;
      }

      &:hover {
        .njc-menu-collapse-button {
          opacity: 1;
        }
      }
    }
  }

  .widget-container {
    .resize-handles {
      &:hover {
        border: 2px solid var(--template-primary-color);
      }
      &.selected {
        border: 1px dashed var(--template-main-50);
        &:hover {
          border: 1px dashed var(--template-main-50);
        }
      }
    }
    .table-resize-column-width-bar {
      border-color: var(--template-main-50) !important;
    }
    .dndr-resize-handle {
      background-color: var(--template-main-0);
      border: 1px solid var(--template-main-80);
      width: 12px !important;
      height: 12px !important;

      &.top {
        top: -6px !important;
      }
      &.right {
        right: -6px !important;
      }
      &.bottom {
        bottom: -6px !important;
      }
      &.left {
        left: -6px !important;
      }
      &.center {
        left: 0px !important;
        margin-left: 0px !important;
        width: 100% !important;
        border-radius: 0px;
        border: 0px solid transparent;
        background-color: transparent;
      }
      &.middle {
        top: 0px !important;
        margin-top: 0px !important;
        height: 100% !important;
        border-radius: 0px;
        background-color: transparent;
        border: 0px solid transparent;
      }
      &.top.left , &.bottom.right {
        cursor: nwse-resize;
        z-index: 1010 !important;
      }
      &.top.center , &.bottom.center {
        cursor: ns-resize;
      }
      &.top.right , &.bottom.left {
        cursor: nesw-resize;
        z-index: 1010 !important;
      }
      &.middle.left , &.middle.right {
        cursor: ew-resize;
      }
    }

    &.selected {
      .dndr-cancel , .dndr-locked {
        display: none;
        svg {
          color: var(--template-main-80);
          cursor: pointer;
          background-color: var(--template-main-0);
          padding: 1px;
          border-radius: 5px;
          border: 1px solid var(--grayscale-30);
        }
      }
      &:hover {
        .dndr-cancel , .dndr-locked {
          display: block;
        }
      }
      .dndr-cancel {
        svg {
          &:hover {
            color: var(--critical-color);
          }
        }
      }
      .dndr-locked {
        .lockwidget-icon {
          background-color: var(--template-main-0);
        }
        &:hover {
          .lockwidget-icon {
            display: none;
          }
          .unlockwidget-icon {
            display: block !important;
            opacity: 1;
          }  
        }
        svg {
          &:hover {
            color: var(--template-primary-color);
          }
        }
      }
    }

    &.locked {
      .dndr-resize-handle {
        display: none;
      }
      .resize-handles.selected {
        border: 1px solid var(--template-main-50) !important;
      }
    }

    .tooltip-wrapper {
      .njc-temp-more-info-popup-wrap {
        display: none;
        opacity: 0;
        position: absolute;
        color: var(--template-main-100);
        background-color: var(--template-main-5);
        border: 0px solid var(--template-contour);
        padding: 6px 8px;
        font-family: 'Inter', sans-serif;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 0 var(--template-border);
        width: 400px !important;
        right: -35px;
        font-size: 16px;
        bottom: 20px;
        white-space: pre-line;
        transition: opacity 0.2s linear;
      }
      &:hover {
        .njc-temp-more-info-popup-wrap {
          display: flex !important;
          visibility: visible;
          opacity: 1;
          overflow: visible;
        }
      }
    }
  }

  .msg-area {
    background-color: rgba(255,255,255,0.6);
  }

  .rc-menu {
    .menu-container {
      border: 1px solid var(--template-border);
      background-color: var(--template-main-100);
      border-radius: 5px;


      .item {
        height: 34px;
        .njc-flex-direction-row;
        .njc-flex-startcenter;
        padding: 0 4px 0 14px;
        .njc-font-1;
        color: var(--template-main-10);
        font-size: 14px;
        background-color: transparent;
        border-bottom: 1px solid var(--template-border);
        cursor: pointer !important;

        .text {
          font-size: 13px;
          padding-right: 8px;
          cursor: pointer !important;
        }

        .shortcut {
          font-size: 12px;
          color: var(--grayscale-50);
          margin-left: auto;
          margin-right: 4px;
          cursor: pointer !important;
        }
        svg {
          .njc-sizing-sq(16px);
          margin-right: @spacing-2;
          cursor: pointer !important;
        }

        &.item-warn {
          color: var(--critical-color);
          border-radius: 0 0 5px 5px;
          border-bottom: 0px solid transparent;
          &:hover {
            color: var(--critical-color);
          }
        }
        &:hover , &.active {
          background-color: var(--template-contour);
          border-radius: 0px;
        }
        .tiny-arrow-right {
          .njc-sizing-sq(18px);
          margin-left: auto;
          margin-right: 0px;
        }
      }
      .divider {
        display: none;
      }

      > div .item {
        border-radius: 5px 5px 0 0;
      }

      &.secondary-menu {
        width: 170px;
      }
    }
  }
}

/* Widget Status - moved out to also apply in dashboards */
.njc-widget-status {
  .njc-flex-direction-column-center;
  color: var(--template-main-50);
  padding: 12px;

  &.njc-widget-status-error {
    color: var(--critical-color);
  }

  svg {
    width: 34px;
    height: 36px;
  }
  .njc-widget-status-message {
    margin-top: 12px;
    .njc-text-widget-status;

    span {
      font-weight: 700;
    }
  }

  .njc-widget-loading {
    border-radius: 50%;
    .njc-sizing-sq(80px);
    border: 10px solid var(--template-main-10);
    border-top-color: var(--template-main-50);
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}


.select2-container--open {
  .njc-temp-top-account-dropdown {
    margin-top: 0px;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid var(--template-contour);
    border-top: 0px;
    background-color: var(--template-main-100);

    .select2-search--dropdown {
      padding: 8px 13px;

      .select2-search__field {
        .njc-temp-search-input;
        background-color: #151c25;
        border: 1px solid var(--template-border);
        height:28px;
        min-height: 28px;
      }
    }

    .select2-results__option {
      background-color: transparent;
      color: var(--template-main-0);
      font-weight: 500;
      padding: 8px 13px;
      min-height: 28px;
      font-size: 12px;
      white-space: normal;

      &[aria-selected=true] {
        background-color: var(--template-main-90);
        color: var(--template-main-0);
      }

      &:hover {
        background-color: var(--template-main-90);
        color: var(--template-main-20);
      }

      &:after {
        border-bottom-color: var(--template-contour);
        bottom: auto;
        top: 0;
      }

      &:first-child {
        color: #BDC2CC;
      }

      &:first-child , &:last-child {
        border-radius: 0;
      }
    }
  }
}

.swal2-container.swal2-in {
  background-color: rgba(45,53,64,0.9) !important;
}

/*Fix for custom date range in comparisons*/
.nc-modal-template-builder .builder-wrap .right-sidebar .nc-ctx-text-bit .njc-form-search-bar {
  .njc-text-form;
  .njc-text-form-border;
  background-image: url("/images/njc/njc-icon-search.svg");
  padding: 12px 20px 12px 40px;
}
.njc-template-editor .builder-wrap .right-sidebar .w-tab-content .w-tab-pane .panel-wrap-2 .hidden-layout-panel .settings-properties-row .setting-properties-wrap .settings-panel-property-content .njc-date-range-box-wrap {
  color: var(--grayscale-100);
  font-weight: 400;
  font-size: 13px;
}

.njc-template-editor .builder-wrap .right-sidebar .COMPARISON .Modal__content .njc-date-range-relative .njc-checkbox input {
  height: 18px !important;
  width: 18px;
  opacity: 0;
  z-index: 1;
  margin-right: -22px;
  margin-top: 5px;
}

//Google sheets data source mapping styling
.njc-tabled-wrap {
  border: 1px solid var(--grayscale-30);
  border-radius: 5px;
  background-color: var(--grayscale-0);
  box-shadow: 2px 2px 4px rgba(45, 53, 64, 10%);
}
.njc-tabled-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.njc-tabled-headers {
  background-color: var(--grayscale-5);
  height: 40px;
  border-bottom: 1px solid var(--grayscale-30);
  border-radius: 5px 5px 0 0;
  padding: 0 20px;
  z-index: 9;

  .njc-tabled-cell {
    font-weight: 700;
    font-size: 13px;
    color: var(--grayscale-60);
    text-transform: capitalize;
    text-decoration: none;
    line-height: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: left;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
}
.njc-tabled-row {
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  position: relative;
  padding: 0 20px!important;
  font-weight: 400;
  font-size: 13px;
  color: var(--grayscale-100);
  line-height: 16px;
  text-align: left;
  border-bottom: 1px solid var(--grayscale-30);

  .njc-tabled-cell {
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 13px;
    color: var(--grayscale-100);
    line-height: 16px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
}

/*Search Input Close Button*/
input[type="search"] {
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button {
  background-image: url("/images/njc/njc-close.svg");
  width: 15px;
  height: 15px;
  background-size: contain;
}

.widget-extra-info {
  position: relative;
  &:hover {
    .njc-temp-more-info-popup-wrap {
      .njc-temp-more-info-popup;
      top: 95px;
      display: flex !important;
      visibility: visible;
      opacity: 1;
      width: 225px;
      height: auto;
      overflow: visible;
      transition-delay: 0s;

      .njc-temp-more-info-popup-content {
        max-width: none;
      }
    }
  }
}

/* Template full screen styling */

.njc-template-editor .builder-wrap .center-area .support-bar button.njc-template-full-screen-button {
  width: 42px;
  margin-right: auto;
  svg {
    margin-right: 0px;
  }
}
.left-menu-collapsed.njc-template-editor .builder-wrap .center-area {
  &:before {
    content: "";
    position: fixed;
    top: 40px;
    left: 0px;
    width: 14px;
    height: 100%;
    z-index: 0;
    background-color: var(--template-main-100);
    background-image: url("/images/njc/njc-icon-arrow-right-light.svg");
    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: 8px;
  }
}
.right-menu-collapsed.njc-template-editor .builder-wrap .center-area {
  &:after {
    content: "";
    position: fixed;
    top: 40px;
    right: 0px;
    width: 14px;
    height: 100%;
    z-index: 0;
    background-color: var(--template-main-100);
    background-image: url("/images/njc/njc-icon-arrow-left-light.svg");
    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: 8px;
  }
}

.njc-menu-collapse-button {
  position: absolute;
  width: 18px;
  height: 36px;
  top: 4px;
  z-index: 4;
  background-color: var(--template-main-100);
  border: 1px solid var(--template-contour);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--template-main-0);
  cursor: pointer;
  opacity: 0;
  
  transition: opacity 0.2s ease-in-out;
  svg {
    width: 18px;
    height: 18px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -5px;
    width: 30px;
    height: 46px;
  }
}
.njc-left-menu-collapse-button {
  .njc-menu-collapse-button;
  border-left: 0px;
  border-radius: 0 5px 5px 0;
  left: 280px;
  svg {
    transform: rotate(90deg);
  }
  &:before {
    left: 0;
  }
}
.left-menu-collapsed.njc-template-editor .builder-wrap .left-sidebar {
  transition: margin-left 0.2s ease-in-out;
  margin-left: -280px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 280px;
  height: 100%;

  .njc-temp-left-help {
    transition: margin-left 0.2s ease-in-out;
    margin-left: -280px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -16px;
    width: 16px;
    height: calc(~"100% - 77px")
  }
  .njc-left-menu-collapse-button {
    opacity: 0;
  }
  &:hover {
    margin-left: 0px;
    .njc-temp-left-help {
      margin-left: 0px;
    }
    .njc-left-menu-collapse-button {
      opacity: 1;
    }
  }
}

.njc-right-menu-collapse-button {
  .njc-menu-collapse-button;
  right: 241px;
  border-radius: 5px 0 0 5px;
  border-right: 0px;
  svg {
    transform: rotate(-90deg);
  }
  &:before {
    right: 0;
  }
}
.right-menu-collapsed.njc-template-editor .builder-wrap .right-sidebar {
  transition: margin-right 0.2s ease-in-out;
  margin-right: -241px;
  margin-top: 40px;
  right: 0px;
  top: 0px;
  position: fixed;
  width: 241px;
  height: 100%;

  .panel-tabs-menu {
    border-left: 0px solid transparent;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -16px;
    width: 16px;
    height: calc(~"100% - 77px")
  }
  .njc-right-menu-collapse-button {
    opacity: 0;
  }
  &:hover {
    margin-right: 0px;
    .njc-right-menu-collapse-button {
      opacity: 1;
    }
  }
}

.right-menu-collapsed.njc-template-editor .builder-navbar {
  width: 100%;
}

.left-menu-collapsed.njc-template-editor .builder-wrap .center-area .support-bar {
  width: calc(~"100% -" 14px;);
  margin: 0 0 0 14px;
}
.right-menu-collapsed.njc-template-editor .builder-wrap .center-area .support-bar {
  width: calc(~"100% -" 14px;);
  margin: 0 14px 0 0;
}
.left-menu-collapsed.right-menu-collapsed.njc-template-editor .builder-wrap .center-area .support-bar {
  width: calc(~"100% -" 28px;);
  margin: 0 auto;
}

.njc-template-editor-body {
  // Checkbox for SweetAlert modal
  .swal2-checkbox {
      text-align: center;
    input {
      margin: 0 8px 0 0;  
    }
    span {
      font-size: 14px;
      color: var(--grayscale-100);
      font-family: 'Inter', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-size-adjust: 100%;
      text-rendering: optimizeLegibility;
      font-weight: 400;
      font-size: 13px;
      color: var(--grayscale-100);
      line-height: 18px;
    }
  }

  // Multi select dropdown
  .select2-container--open {
    .select2-dropdown {
      .select2-results__option {
        background-color: var(--template-main-90);
        font-size: 11px;
        color: var(--template-main-20);
        font-weight: 400;
        text-align: left;
        line-height: 12px;
  
        &:hover {
          background-color: var(--primary-color) !important;
          color: var(--template-main-5);
          cursor: pointer;
        }
      }
    }
  }
}

// Set a default for the font size of the comparison metric. This used to be set as an inline style, but that had caused problems with other styling not being applied.
// We are using the default here to ensure existing templates do not change their appearance.
// In the template editor global styling, the font is applied with a more specific selector, so it will override this default.
// In the template editor table widget, the font is applied with an inline style, so it will override this default.
.comparison-metric-cell-inner-font {
    font-size: 15px;
    font-weight: normal;
}

// Styling for range bit
.njc-range-bit-wrap {
  width: 100%;
  display: flex;
  .property-label {
    min-width: 60px;
    margin-right: 8px;
    align-items: center;
  }
}
.njc-range-bit {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  position: relative;
  width: 100%;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--template-main-80);
    height: 2px; // Set the height of the range track
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 2px; // Set the height of the track
    -webkit-appearance: none;
    color: var(--template-main-0);
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    cursor: ew-resize;
    background: var(--template-main-0);
    border-radius: 50%;
    margin-top: -4px; // Adjust the thumb position to center it on the track
    -webkit-appearance: none;
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
background-color: var(--template-main-0); 
}
input[type="range"]::-moz-range-track {  
background-color: var(--template-main-80);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
background-color: var(--template-main-0); 
}
input[type="range"]::-ms-fill-upper {  
background-color: var(--template-main-80);
}

input[type="range"]:disabled {
  cursor: not-allowed;
  &::-webkit-slider-thumb {
    background: var(--template-main-80);
    cursor: not-allowed;
  }
}

.njc-range-number {
    width: 54px;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    font-size: 11px;
    color: var(--template-main-20);
    font-weight: 400;
    text-align: left;
    line-height: 12px;
    border: 1px solid var(--template-contour);
    border-radius: 5px;
    background-color: var(--template-hover);
    height: 26px;
    background-image: none;
    padding: 0 16px 0 8px;
    margin-bottom: 0px;
    margin-left: 8px;

    &:disabled {
      color: var(--grayscale-70);
    }
}

.njc-range-bit-symbol {
    position: absolute;
    top: 7px;
    right: 3px;
    font-size: 11px;
    color: var(--template-main-20);
    font-weight: 400;
    text-align: left;
    line-height: 12px;
}

.njc-temp-separator {
  width: calc(~"100% - 26px");
  height: 1px;
  background-color: var(--grayscale-80);
  margin: 15px 13px;
}

// Shadow Bit Styling
.njc-shadow-bit {
  flex-direction: column;
  align-items: flex-start;
  margin: 4px 0;

  .colorpicker-bit {

    .njc-temp-color-select-wrap;
    .njc-flex-direction-row;
    .njc-flex-startcenter;

    .njc-temp-styling-left-label {
      width: 60px !important;
      margin-right: 8px;
      display: flex !important;
      .njc-temp-right-panel-label-header;
    }
  }
  
}

.njc-temp-child-section {
  .njc-shadow-bit {
    border-left: 2px solid var(--template-main-80);
  }
}

// Disabled styling
.disabled {
  .colorpicker-bit , .sp-replacer , .njc-range-bit-wrap {
    cursor: not-allowed;
  }
}

// Template Editor Setting Title
.njc-template-editor-setting-title {
  .njc-temp-tab-title-text;
}

.njc-temp-shape-widget-menu {
  .njc-temp-widget-column-select-option-content {
    height: 100% !important;
    svg {
      margin-top: 12px !important;
    }
  }
}
// Remove a non-removable border line from the table widget that appeared at the bottom of the th
.nc-modal-template-builder .table-widget .table-column-headers {
  border-bottom: 0px;
}

// Template Editor Modal DropdownError Styling
.nc-template-builder-modal {
  .hasError {
    .settings-panel-property-dropdown {
      border: 2px solid var(--critical-color) !important;
    }
  }
  
  .error-message {
    color: var(--critical-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
    text-align: left;
    .lowercase {
      text-transform: lowercase;
    }
  }
}

.nc-modal-template-builder .widget-container pre {
  font-family: inherit;
  margin: 0;
  display: inline;
}

// Allows selected widget to be resized even if the widget is behind other widgets
.nc-modal-template-builder .widget-container.notLocked .resize-handles.selected {
  z-index: 250 !important;
}

.njc-template-widget-type-modal-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 0 0 0;
}

.njc-template-widget-type-modal-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(~"16% - 8px");
  gap: 4px;
  border-radius: 5px;
  border: 3px solid transparent;
  cursor: pointer;
  padding: 4px;

  .njc-template-widget-type-modal-item-text {
    font-size: 12px;
    color: var(--template-main-80);
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }

  svg {
    color: var(--template-main-80);
    width: 24px;
    height: 24px;
  }

  &.selected {
    border: 3px solid var(--template-main-80);

    .njc-template-widget-type-modal-item-text {
      color: var(--template-main-100);
    }

    svg {
      color: var(--template-main-100);
    }
  }
}

.nc-modal-template-builder .widget-container .wrap pre , 
.nc-modal-template-builder .widget-container .wrap-break-word pre {
  white-space: pre-wrap;
}

// Edit Data Modal Styling
.njc-edit-data-cell {
  width: 100%;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 4px 3px;
  margin: 2px;
}

.njc-edit-data-modal {
  .Modal.u-overlay.animated {
    overflow-y: auto;
    background-color: rgba(45, 53, 64, 0.9);
  }

  &.expanded {
    .Modal__container.animated {
      max-width: 95%;
    }
  }

  .Modal__container.animated {
    width: 95%;
    max-width: 1040px;
    margin: auto;  
    padding: 0px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);

    .njc-small-modal-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      .njc-modal-title-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .njc-modal-title-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        .njc-modal-user {
          font-size: 12px;
          color: var(--grayscale-100);
          background-color: var(--grayscale-10);
          border-radius: 5px;
          padding: 4px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }

    .njc-form-footer-row {
      padding-bottom: 0px;
      width: 100%;
      .njc-form-footer-row-container {
        width: calc(~"100% + 100px");
      }
    }
  }

  .Modal__content {
    margin: 32px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(~"95vh - 52px");
    position: relative;

    .modal-buttons {
      position: absolute;
      top: -20px;
      right: -26px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      .modal-button {
        &:hover {
          background-color: transparent;
        }
      }
      .njc-icon-expand {
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .njc-icon-close {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .njc-table-container {
    max-height: 100%;
    height: fit-content;
    width: 100%;
    overflow: auto;
    margin-top: 22px;
    border-radius: 5px;
    &::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: var(--grayscale-0);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--grayscale-30);
      border: 2px solid var(--grayscale-0);
      border-radius: 7px;
      min-height: 10px;
      min-width: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--grayscale-50); 
    }
  
    &::-webkit-scrollbar-corner {
      background: var(--grayscale-0); 
    }
  }

  .njc-table-body {
    display: table;
    width: auto;
    min-width: 100%;
    table-layout: auto;
  }

  .njc-table-row {
    display: table-row;

    &.new {
      background-color: var(--success-color-5);
    }

    .njc-table-cell {
      border-bottom: 1px solid var(--grayscale-30);
      &:first-child {
        border-left: 1px solid var(--grayscale-30);
      }
      &:last-child {
        border-right: 1px solid var(--grayscale-30);
      }
    }

    &.njc-table-header-row {
      .njc-table-cell {
        border-top: 1px solid var(--grayscale-30);
        border-bottom: 2px solid var(--grayscale-30);
        &:first-child {
          border-radius: 5px 0 0 0;
        }
        &:last-child {
          border-radius: 0 5px 0 0;
        }
      }
    }

    &.njc-table-footer-row {
      position: sticky;
      bottom: 0;
      z-index: 2;
      background-color: var(--grayscale-0);

      &.has-comparison-row {
        bottom: 43px;

        .njc-table-cell:first-child,
        .njc-table-cell:last-child {
          border-radius: 0;
        }
      }
      .njc-table-cell {
        border-top: 2px solid var(--grayscale-30);
        &:first-child {
          border-radius: 0 0 0 5px;
        }
        &:last-child {
          border-radius: 0 0 5px 0;
        }
      }
    }

    &.selected {
      background-color: var(--primary-color-5);
    }

    &.selected-for-delete {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: var(--critical-color-50);
        animation: RowDeleteCoverAnimation 200ms ease-in forwards;
      }
      
      animation: RowDeleteAnimation 300ms ease-in forwards;
      transform-origin: top;
    }
  }

  .njc-table-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 8px;
    font-size: 13px;
    color: var(--template-main-100);
    font-weight: 400;
    line-height: 16px;
    height: 40px;
    min-width: 100px;
    white-space: nowrap;
    text-align: left;
    &.number {
      text-align: right;
    }

    &.njc-table-header-cell,
    &.njc-table-footer-cell {
      font-weight: 700;
    }
  }

  .njc-data-cell {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    min-height: 24px;
    border-radius: 3px;

    &.number {
      justify-content: flex-end;
    }

    &.empty {
      border: 2px dashed var(--grayscale-10);
    }

    &:hover {
      border: 2px solid var(--template-main-30);
    }
  }

  .njc-table-cell-checkbox {
    position: sticky;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: var(--grayscale-0);
    border-right: 1px solid var(--grayscale-30);
    width: 74px;
    min-width: 74px;
    text-align: center;
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 1px solid var(--grayscale-30);
      background-color: var(--grayscale-0);
      cursor: pointer;
      position: relative;

      &:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
  
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          background-image: url('/images/njc/njc-checkbox.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &.partial {
        &:checked {
          &::after {
            background-image: url('/images/njc/njc-checkbox-partial.svg');
          }
        }
      }

      &:disabled {
        cursor: not-allowed;
        background-color: var(--grayscale-10);
      }
    }
  }

  .njc-input-field {
    width: 100%;
    height: 24px;
    &[type="number"] {
      text-align: right;
    }
  }

  .njc-table-actions-container {
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .njc-table-action-link {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 4px;
      margin-top: 18px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: var(--grayscale-100);
      svg {
        width: 16px;
        height: 16px;
      }
      &:hover {
        text-decoration: underline;
      }
      &.disabled {
        cursor: not-allowed;
        color: var(--grayscale-50);
        &:hover {
          text-decoration: none;
        }
      }
      &.njc-table-action-delete-link {
        color: var(--critical-color);
      }
    }
  }
}

.njc-widget-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

@keyframes RowDeleteAnimation {
  0%, 66.67% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes RowDeleteCoverAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}